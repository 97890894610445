/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { RenderSections } from '@components/UI/Common/SectionCommonUI';

/* Services */

/**
 * Renders the CenterCommonUI component.
 * @param {Array} pageBody - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} CenterUI component.
 */
const CenterCommonUI = ({ pageBody, region, lang }) => {
  // const apiSections = ['body', 'bodyLeftNavSections', 'bodyBottomSection'];
  return (
    <Box
      as="section"
      id="centers-section"
      className="container-article btn-center"
      sx={{
        '.image-container': {
          minWidth: { base: '328px', md: '559px' },
          minHeight: { base: '240px', md: '410px' },
        },
      }}
    >
      {pageBody && pageBody?.length ? (
        <ParseBody
          pageBody={pageBody}
          region={region}
          lang={lang}
          pType="center"
        />
      ) : null}
    </Box>
  );
};

const ParseBody = ({ pageBody, region, lang, pType }) => {
  return pageBody && pageBody.length
    ? pageBody.map((section, i) => {
        return (
          <Box key={i}>
            <RenderSections
              section={section}
              region={region}
              lang={lang}
              pType={pType}
              key={i}
            />
          </Box>
        );
      })
    : null;
};

export default CenterCommonUI;
