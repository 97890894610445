/* Built In Imports*/
import { useEffect } from 'react';
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components  */
import config from '@config';

/* Services */
/**
 * Renders the US ChatBot Component
 *
 * @param
 * @returns {ReactElement} - US Chat Bot Component
 */
export default function USChatBot({region}) {
  const reg = region;
  // useEffect(() => {
  //   if (reg == 'us') {
  //     window.$crisp = [];
  //     (function () {
  //       let sourceurl = '';
  //       if (config.ENV_NAME === 'production') {
  //         sourceurl = `https://d3dqyamsdzq0rr.cloudfront.net/prod/widget/chat/js/sia-ai-chat-widget-soks-global-v2.js`;
  //       } else {
  //         sourceurl = `https://d3dqyamsdzq0rr.cloudfront.net/widget/chat/js/sia-ai-chat-widget-soks-global-v2.js`;
  //       }
  //       let d = document;
  //       let s = d.createElement('script');
  //       s.src = sourceurl;
  //       s.async = 1;
  //       d.getElementsByTagName('head')[0].appendChild(s);
  //     })();
  //   }
  // }, []);
  const getScript = url =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onerror = reject;
      script.onload = script.onreadystatechange = function () {
        const loadState = this.readyState;
        if (loadState && loadState !== 'loaded' && loadState !== 'complete')
          return;
        script.onload = script.onreadystatechange = null;
        resolve();
      };
      document.head.appendChild(script);
    });
  useEffect(() => {
    if (typeof PrsSchedule === 'undefined') {
      console.log('PrsSchedule === undefined');
      getScript(
        (location.origin == 'https://isha.sadhguru.org'
          ? 'https://d3dqyamsdzq0rr.cloudfront.net/prod/widget/chat/js/sia-ai-chat-widget-soks-global-v2.js'
          : 'https://d3dqyamsdzq0rr.cloudfront.net/prod/widget/chat/js/sia-ai-chat-widget-soks-global-v2.js') 
      );
    }
  });
  console.log('uschatregion1', region);
  useScript('https://d3dqyamsdzq0rr.cloudfront.net/prod/widget/chat/js/sia-ai-chat-widget-soks-global-v2.js');
  return (
    <>
    {/* {reg == 'us' && ( */}
    <Box id={config.ENV_NAME} className="USChatbot">
        <Box
          id="skilai-x2n331-widget-container"
          bot-lang="en"
          bot-id="s_623df5"
          bot-auth-key="HYYIYBHSHJSDJSDJK"
          className='skil-ai-v-css'
        ></Box>
    </Box>
    {/* )} */}
    </>
  );
}

const useScript = (src) => {
  useEffect(() => {
    const scriptExists = document.querySelector(`script[src="${src}"]`);
    if (!scriptExists) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.head.appendChild(script);  
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [src]);
};