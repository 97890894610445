
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[region]/center/consecrated-spaces/[spaces]",
      function () {
        return require("private-next-pages/[region]/center/consecrated-spaces/[spaces]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[region]/center/consecrated-spaces/[spaces]"])
      });
    }
  