/* Built In Imports */
import { useContext, useState, useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import WrapLayout from '@Layout/WrapLayout';
import Breadcrum from '@components/Navigation/Breadcrum';
import CenterCommonUI from '@components/UI/Center';
import LeftNavCommonUI from '@components/UI/Common/LeftNavCommonUI';
import HeadComponent from '@components/Utility/HeadComponent';
import USChatBot from '@components/Utility/USChatBot';
import config from '@config';
import { AuthContext } from '@store/auth-context';
import { useRouter } from 'next/router';
import Loader from '@components/Utility/Loader';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { fetchDetails, getLandingPaths } from 'services/centerService';
import {
  fetchFooterData,
  fetchHeaderMenuData,
  fetchHeaderTopMenuData,
} from 'services/commonService';

/* Styles */

/**
 *
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Health Landing Page Data
 */
const Center = ({ region, language, pageData }) => {
  const authContext = useContext(AuthContext);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {

    const loadTime = performance.now(); // Get the load time in milliseconds    
    // You can set a minimum loading time if desired
    const minimumLoadTime = 2000; // 2 seconds
    const actualLoadTime = Math.max(loadTime, minimumLoadTime);
    const timer = setTimeout(() => {
      setIsLoader(false); // Hide loader after actualLoadTime seconds
    }, actualLoadTime); // Minimum duration for the loader

    const handleLoad = () => {
      clearTimeout(timer); // Clear the timer if the page loads before actualLoadTime seconds
      setIsLoader(false); // Hide loader when page is fully loaded
    };

    window.addEventListener('load', handleLoad);

    return () => {
      clearTimeout(timer); // Cleanup timer on unmount
      window.removeEventListener('load', handleLoad); // Cleanup event listener
    };
  }, []);


  const router = useRouter();
  const centerPages = ['/adiyogi-abode-of-yoga', '/mahima-hall'];
  let centerUrl;
  centerPages?.map(pageUrl => {
    if (centerUrl != true) {
      centerUrl = router.asPath.includes(pageUrl);
    }
  });

  return (
    <WrapLayout>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Breadcrum
        page={pageData.breadcrumbs}
        alsoin={pageData.alsoin}
        url=""
        region={authContext.region}
        lang={language}
        pageConfig={pageData?.pageConfig}
      />
      <Box as="section" className="page-wrap container-article" p="0">
        <CenterCommonUI
          pageBody={pageData.body}
          region={authContext.region}
          lang={language}
        />
        <LeftNavCommonUI
          pageBody={pageData.bodyLeftNavSections}
          region={authContext.region}
          lang={language}
        />
        <CenterCommonUI
          pageBody={pageData.bodyBottomSection}
          region={authContext.region}
          lang={language}
        />
        {centerUrl && <USChatBot region={authContext.region}></USChatBot>}
      </Box>
      {isLoader && (
        <Box w="100%" position="fixed" left="0" top="0" bgColor="rgba(0, 0, 0, 0.48)" height="100%" zIndex="9888" textAlign="center" display={'block'}>
          <Loader pos="relative" />
        </Box>
      )}
    </WrapLayout>
  );

};

export const getStaticPaths = async () => {
  const centerLandingPaths = config.USE_LOCAL_URL
    ? [
        `/en/center/consecrated-spaces/theerthakund`,
        `/en/center/consecrated-spaces/dhyanalinga`,
        `/en/center/consecrated-spaces/linga-bhairavi`,
        `/en/center/consecrated-spaces/adiyogi-abode-of-yoga`,
        `/en/center/consecrated-spaces/112-feet-adiyogi`,
        `/en/center/consecrated-spaces/adiyogi-abode-of-yoga`,
        `/en/center/consecrated-spaces/mahima-hall`,
      ]
    : await getLandingPaths('L2', '/center/consecrated-spaces/');
  // consoleLog('centerLandingPaths', centerLandingPaths);
  return {
    paths: centerLandingPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, spaces },
  req,
}) => {
  const language = region;
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale('', language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale('', language),
    'iso_non_regional_menu'
  );
  const footerData = await fetchFooterData(
    getLocale('', language),
    'isofooter'
  );
  const pageData = await fetchDetails(
    '',
    language,
    `consecrated-spaces/${spaces}`,
    { preview, data: previewData }
  );

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: '',
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      isPreview: preview ? true : false,
    },
  };
};

export default Center;
